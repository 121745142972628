/* eslint-disable */
  import { LocaleWrapper } from '@empiriecom/module-components/IntlProvider';
  import deepmerge from 'deepmerge';
  import ioLocale from '@empiriecom/mybuy-components/locales/ottoversandat';
  
  const messages: LocaleWrapper = {
  "de-AT": {
    "APlusList.headline": "Deine bereits gebuchten Services",
    "AccountInformation.headline": "Unsere Bankverbindung",
    "AccountInformation.qrPayment.Or": "ODER",
    "AccountInformation.qrPayment.infoText": "Nutzen Sie den QR-Zahlschein über den unten stehenden Link:",
    "AccountInformation.receiverNameWithBank": "Bankname",
    "AccountInformation.receiverTitle": "Empfänger:",
    "AccountInformation.subject": "Bitte trage im Feld \"ZAHLUNGSREFERENZ\" deine Kundennummer <strong>{bankTransferReference}</strong> ein.",
    "AccountSettings.Autologin": "automatische Anmeldung",
    "AccountSettings.Autologin.Headline": "Aktiviere die automatische Anmeldung.",
    "AccountSettings.Autologin.Text": "Beim nächsten Besuch erkennen wir dich wieder und du sparst dir die Zeit, dich jedes Mal extra anzumelden.",
    "AccountSettings.Delivery": "Zustellung",
    "AccountSettings.Headline": "Einstellungen",
    "AccountSettings.MoreInformation": "weitere Informationen",
    "AccountSettings.NeighboursDelivery.Headline": "An Nachbar zustellen:",
    "AccountSettings.NeighboursDelivery.Infotext": "Der Zusteller kann mein Paket beim Nachbarn abgeben, wenn ich nicht zu Hause bin. Eine Änderung dieser Lieferpräferenz ist nur für Artikel möglich, die noch nicht in Rechnung gestellt wurden.",
    "AccountSettings.NeighboursDelivery.Text": "In deiner Abwesenheit kannst du Pakete auch an Nachbarn zustellen lassen",
    "AccountSettings.Newsletter.Headline": "Newsletter",
    "AccountSettings.Newsletter.HeadlineModal": "Newsletter",
    "AccountSettings.Newsletter.Infotext": "Kostenlose News zu Sparvorteilen, Trends rund um Mode, Schuhe und Wohnen, Sale und Aktionen – so verpasst du garantiert nichts mehr. Aktivierst du hier den Newsletter, schicken wir dir einen Bestätigungslink per Mail. Mit einem Klick darauf, bist du dabei.",
    "AccountSettings.Newsletter.Text": "Sicher dir News zu Trends, Aktionen und inspirierende Ideen.",
    "AccountSettings.Notifications": "Benachrichtigungen",
    "AccountSettings.PostalBankStatement.Headline": "Postalische Kontoauszüge:",
    "AccountSettings.PostalBankStatement.Infotext": "TODO",
    "AccountSettings.PostalBankStatement.Text": "Lasse dir Kontoauszüge per Post zuschicken (zzg. 1,50 €)",
    "AccountSettings.ServiceMail.Headline": "Service-Infos",
    "AccountSettings.ServiceMail.Text": "So entgehen dir keine Infos über deine Bestellungen.",
    "AccountSettings.ServiceMail.entry0": "Versandbestätigungen",
    "AccountSettings.ServiceMail.entry1": "Retoureneingangsbestätigungen",
    "AccountSettings.ServiceMail.entry2": "Zahlungseingangsbestätigungen",
    "AccountSettings.ServiceMail.entry3": "Bewertungsmails",
    "AccountSettings.ServiceMail.entry4": "Anzahlungs-Aufforderungen",
    "AccountSettings.ServiceMail.entry5": "-",
    "AccountSettings.ServiceMail.entry6": "-",
    "AccountSettings.ServiceMail.entry7": "-",
    "AccountSettings.ServiceMail.entry8": "-",
    "AccountSettings.ServiceMail.entry9": "-",
    "AccountSettings.ServiceMailInfo.Headline": "Wir halten dich per Mail über alle Themen rund um deine Bestellung auf dem Laufenden. So entgeht dir nichts. Du erhältst z.B.",
    "AccountSettings.SmsNotification.AddMobileNumber": "Handynummer hinzufügen",
    "AccountSettings.SmsNotification.ChangeMobileNumber": "Handynummer ändern",
    "AccountSettings.SmsNotification.Headline": "SMS Genehmigung:",
    "AccountSettings.SmsNotification.Infotext": "Wenn ich bei der Bestellung die Lieferung an einen PostNL-Punkt wähle oder einen großen Artikel von Dynalogic zu mir nach Hause liefern lasse, möchte ich bei Lieferung meiner Bestellung eine kostenlose SMS erhalten.",
    "AccountSettings.SmsNotification.MobilePhoneNumber": "Gewählte Handynummer:{break}{phoneNumber}",
    "AccountSettings.SmsNotification.Text": "Erhalte Benachrichtigungen per SMS",
    "AccountSettings.eInvoice.Headline": "Online-Rechnung",
    "AccountSettings.eInvoice.InfoBenefits": " ",
    "AccountSettings.eInvoice.Infotext": "Wir benachrichtigen dich per Mail, sobald eine digitale Rechnung im Kundenkonto vorliegt und du diese abrufen kannst. So schonen wir gemeinsam die Umwelt.",
    "AccountSettings.eInvoice.Text": "Entscheide dich für die Umwelt und gegen Papierrechnungen.",
    "Aplus.included": "inklusive",
    "BankstatementsContainer.Overview.downloadCardButton": "Herunterladen",
    "BankstatementsContainer.Overview.downloadCardDescription": "Um die vollständige Aufschlüsselung zu sehen, laden Sie diesen Kontoauszug als PDF-Datei herunter (kostenlos)",
    "BankstatementsContainer.Overview.downloadCardTitle": "Diesen Kontoauszug herunterladen",
    "BankstatementsContainer.Overview.downloadCardWarning": "Leider ist beim Herunterladen ein Fehler aufgetreten. Versuchen Sie es zu einem späteren Zeitpunkt erneut.",
    "BankstatementsContainer.Overview.overviewPeriodBallanceEnd": "Ende Saldo",
    "BankstatementsContainer.Overview.overviewPeriodBallanceEndOfPeriod": "Saldo (Ende des Monats)",
    "BankstatementsContainer.Overview.overviewPeriodBallanceNew": "Neuer Saldo",
    "BankstatementsContainer.Overview.overviewPeriodBallancePreviousPeriod": "Saldo der vorherigen Abrechnung",
    "BankstatementsContainer.Overview.overviewPeriodBallanceStart": "Beginn Saldo",
    "BankstatementsContainer.Overview.overviewPeriodBallanceStartOfPeriod": "Saldo (Beginn des Monats)",
    "BankstatementsContainer.Overview.overviewPeriodEmpty": "Sie haben keinen aktuellen Kontoauszug. Bei älteren Kontoauszügen wählen Sie im Dropdown den gewünschten Zeitraum aus.",
    "BankstatementsContainer.Overview.overviewPeriodLabel": "Zeitraum auswählen",
    "BankstatementsContainer.Overview.perEmailReceiveCardDescriptionMain": "Postalische Kontoauszüge",
    "BankstatementsContainer.Overview.perEmailReceiveCardDescriptionSecondary": "Lassen Sie sich Kontoauszüge per Post zuschicken (zzg. 1,50 €)",
    "BankstatementsContainer.Overview.perEmailReceiveCardLearnMore": "Weitere Informationen",
    "BankstatementsContainer.Overview.perEmailReceiveCardLearnMoreContent": "TODO",
    "BankstatementsContainer.Overview.perEmailReceiveCardLearnMoreHeadline": "Postalische Kontoauszüge:",
    "BankstatementsContainer.Overview.perEmailReceiveCardTitle": "Kontoauszug per Post auf Papier erhalten",
    "BankstatementsContainer.Overview.title": "Buchungsübersicht",
    "BankstatementsContainer.introtext": "Hier finden Sie Ihre Kontoauszüge.{break}Alle Buchungen und Ihren aktuellen Kontostand finden Sie in Ihrem Kundenkonto.",
    "BankstatementsContainer.title": "Meine Kontoauszüge",
    "BookingContainer.Kim.title": "Wie gefällt dir die Buchungsseite?",
    "BookingContainer.bookingOverview.headline": "Buchungsübersicht",
    "BookingContainer.bookings.headline": "Meine Buchungen",
    "BookingListContainer.BookingList.defaultAsyncErrorMessage": "Entschuldige bitte, wir können deine Buchungen zur Zeit leider nicht abholen. <reload>Bitte lade die Seite neu!</reload> Falls der Fehler weiterhin besteht, haben wir intern ein technisches Problem und sind bereits dabei es zu beheben. Versuche es dann später noch einmal. Vielen Dank für dein Verständnis.",
    "BookingListContainer.BookingList.emptyBookingListHeadline": "Keine Buchungen?",
    "BookingListContainer.BookingList.emptyBookingListText": "Dann hast du noch nicht bei uns bestellt - stöbere in unserem vielfältigen Sortiment und sichere dir dein neues Lieblingsteil",
    "BookingListContainer.BookingList.fullViewSelectionText": "Alle Buchungen",
    "BookingListContainer.BookingList.invoiceViewSelectionText": "Rechnungen",
    "BookingListContainer.BookingList.loadMorebutton": "weitere laden",
    "BookingListContainer.BookingList.paymentViewSelectionText": "Zahlungen",
    "BookingListContainer.BookingList.reimbursementViewSelectionText": "Rücksendungen",
    "BookingListElement.from": "vom ",
    "CCLogin.email.label": "E-Mail-Adresse",
    "CCLogin.email.placeholder": "name@provider.at",
    "CCLogin.label.login": "Anmelden",
    "CCLogin.password.placeholder": "****",
    "CCLogin.title": "Callcenter Anmeldung",
    "CCLoginAsCustomer.customerNoLabel": "Kundennummer",
    "CCLoginAsCustomer.dobLabel": "Geburtsdatum",
    "CCLoginAsCustomer.submitLabel": "Als Kunde anmelden",
    "CCLoginAsCustomer.title": "Anmeldung als Kunde",
    "CallcenterLoginContainer.CCADFSLoginResponse.errorMessage": "Deine Anfrage konnte nicht verarbeitet werden. Versuche es bitte erneut!",
    "CancelShipmentAction.label.cancel": "Abbrechen",
    "CancelShipmentAction.label.cancelOrder": "Artikel stornieren",
    "CancelShipmentAction.label.cancelOrder.confirm": "Stornieren",
    "CancelShipmentAction.label.close": "Schließen",
    "CancelShipmentAction.modal.title.confirm": "Stornierung bestätigen",
    "ChangeIban.cancel": "abbrechen",
    "ChangeIban.defaultError": "Es ist ein technischer Fehler aufgetreten",
    "ChangeIban.headline": "IBAN ändern",
    "ChangeIban.inputLabel": "Neue IBAN",
    "ChangeIban.inputPlaceholder": "DE00000000000000000000",
    "ChangeIban.noDataError": "Die Daten wurden gespeichert, können aber nicht angezeigt werden",
    "ChangeIban.save": "Änderungen speichern",
    "ChangePassword.Howto.headline": "Wie sieht ein sicheres Passwort aus?",
    "ChangePassword.Howto.list.1": "Kombiniere kleine und große Buchstaben, Zahlen und Sonderzeichen",
    "ChangePassword.Howto.list.2": "Wähle mindestens acht bis zehn Zeichen",
    "ChangePassword.Howto.list.3": "Verwende keine Namen oder Geburtsdaten",
    "ChangePassword.Howto.list.4": "Zeichenfolgen wie \"abc\", \"quertz\" oder \"123\" sollten vermieden werden",
    "ChangePassword.Howto.list.5": "Verwende keine Passwörter mehrfach",
    "ChangePassword.Howto.text": "Kennwörter wie \"123456\", \"hallo\" und \"passwort\" stehen ganz oben auf der Beliebtheitsskala, sind aber ziemlich unsicher. Mit diesen fünf Tipps erstellen Sie ein gutes, sicheres Passwort:",
    "ChangePassword.SecurityHint.headline": "Sicherheit ist uns wichtig",
    "ChangePassword.SecurityHint.text": "Dein Passwort muss aus {minLength}-{maxLength} Zeichen bestehen, achte auch auf Groß- und Kleinschreibung",
    "ChangePassword.errorFromBackend": "Ihre Anfrage kann gerade nicht bearbeitet werden. Versuchen Sie es später erneut.",
    "ChangePassword.new.label": "Neues Passwort *",
    "ChangePassword.passwordsDontMatch": "Die beiden Passwörter stimmen nicht überein.",
    "ChangePassword.passwordstooLong": "Das angegebene Kennwort ist zu lang (max. {maxLength} Zeichen)",
    "ChangePassword.passwordstooShort": "Das Passwort muss mindestens {minLength} Zeichen beinhalten.",
    "ChangePassword.placeholder": "{minLength}-{maxLength} Zeichen",
    "ChangePassword.repeat.label": "Neues Passwort wiederholen *",
    "ChangePassword.submit": "Neues Passwort übernehmen",
    "CircleStats.innerTextType.toPay": "Zahlbar vor {Datum}:",
    "CircleStats.innerTextType.withdraw": "Am {Datum}, {Bruch} wird dieser Betrag{Bruch} eingezogen:",
    "CircleStats.sublineTextType.increasedDueToCustomersWish": "Auf Ihren eigenen Wunsch hin wurde der Pausenbetrag angepasst.",
    "CircleStats.sublineTextType.increasingPossible": "Sie können jederzeit ohne zusätzliche Kosten mehr bezahlen.",
    "ContactFormLink": "<link>https://www.ottoversand.at/service-hilfe/kontakt</link>",
    "CustomerInformation.BusinessPartnerNo": "Kundennummer: {businessPartnerNo}",
    "CustomerInformation.LogoutText": "Du bist nicht {name}? Jetzt abmelden",
    "CustomerInformation.PersonalDataLink": "Meine persönlichen Daten",
    "CustomerInformation.PremiumRenewalDate": "(verlängert sich am {placeholder})",
    "DataExport.Button": "Daten herunterladen",
    "DataExport.DisabledButton": "Exportiere... ",
    "DataExport.DownloadError": "Leider ist beim Export der Daten ein Fehler unterlaufen. Versuche es zu einem späteren Zeitpunkt erneut.",
    "DataExport.DownloadSuccess": "Deine Daten wurden heruntergeladen.",
    "DataExport.Headline": "Datenexport",
    "DataExport.NoDownloadText": "Natürlich kannst du deine persönlichen Daten, die du angegeben hast, jederzeit herunterladen – ohne Auswirkungen auf dein Kundenkonto.{break}{break}Diese Funktion ist leider nur im Browser verfügbar. Melde dich einfach mit deinem Handy oder Computer auf deinem Browser an, um die Datei herunterzuladen.",
    "DataExport.Subheadline": "Deine Daten, deine Sache.",
    "DataExport.Text": "Natürlich kannst du deine persönlichen Daten jederzeit herunterladen – ohne Auswirkungen auf dein Kundenkonto.{break}{break}Alles bleibt wie gewohnt für dich nutzbar und bei uns gespeichert. So kannst du die Daten archivieren oder sie für einen anderen Dienst nutzen.",
    "EInvoice.Download.Button": "Herunterladen und Drucken",
    "EInvoice.Download.DisabledButton": "Bitte warten ...",
    "EInvoice.Download.Error": "Leider ist beim Herunterladen ein Fehler aufgetreten. Versuche es zu einem späteren Zeitpunkt erneut.",
    "EInvoice.aplus.headline": "Gebuchte Services",
    "EInvoice.error.description": "In unserem System ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
    "EInvoice.footer.businessaddress": "Otto GmbH, Alte Poststraße 152, 8020 Graz, USt.-IDNr ATU 57182300.",
    "EInvoice.footer.businessterms": "Bitte beachte unsere Geschäftsbedingungen unter <link>ottoversand.at/agb</link>.",
    "EInvoice.footer.fixed.info.first": "Zahlbar bei Rechnungskauf innerhalb von 14 Tagen, bei Ratenkauf innerhalb von 30 Tagen und bei Zahlpause innerhalb von 120 Tagen nach Erhalt der Lieferung. Bitte beachte, dass du spätestens 30 Tage nach Zugang dieser Rechnung und Fälligkeit der Forderung in Verzug gerätst, ohne dass es einer weiteren Mahnung bedarf.",
    "EInvoice.footer.fixed.info.second": "Bei Vorliegen eines gültigen Lastschriftmandates werden wir die ausgewiesenen fälligen Beträge einziehen. Retouren vermindern den Einzugsbetrag.",
    "EInvoice.footer.fixed.info.third": "Bitte gib unbedingt in deiner Überweisung Kundennummer und Rechnungsnummer im Verwendungszweck an. Du beschleunigst damit die Gutschrift auf deinem Kundenkonto.",
    "EInvoice.footer.ownership": "Die Ware bleibt bis zur vollständigen Bezahlung unser Eigentum.",
    "EInvoice.header.customerNumber.headline": "Kundennummer:",
    "EInvoice.header.deliveryAddress.headline": "Lieferanschrift:",
    "EInvoice.header.invoiceAddress.headline": "Rechnungsanschrift:",
    "EInvoice.header.invoiceDate.headline": "Rechnungsdatum:",
    "EInvoice.header.invoiceNumber.headline": "Rechnungsnummer:",
    "EInvoice.header.paymentType.headline": "Zahlungsart:",
    "EInvoice.invoiceTotals.headline": "Rechnung",
    "EInvoice.invoiceTotals.subTotals": "Zwischensumme:",
    "EInvoice.invoicetotals.grandTotals": "Gesamtsumme:",
    "EInvoice.paymentplan.headline": "Zahlungsplan",
    "EInvoice.paymentplan.showEffectiveInstallmentsHint": "Dies ist deine ursprüngliche Rate.{break}Die aktuellen Raten inkl. der Zusammensetzung findest du <link>hier</link>.",
    "EInvoice.products.articleNumber": "Art.Nr.: {articlenumber}",
    "EInvoice.products.finalPrice": "Endpreis:",
    "EInvoice.products.installments": "in {installmentCount} Monatsraten",
    "EInvoice.products.paymentMethod": "Zahlungsart: {paymentMethod}",
    "EInvoice.products.pricesHeadline": "Rechnung",
    "EInvoice.products.productHeadline": "Artikel",
    "EInvoice.products.productPrice": "Warenwert:",
    "EInvoice.products.productReturned": "Produkt wurde zurückgeschickt",
    "EInvoice.products.quantity": "Menge: {quantity}",
    "EInvoice.products.reclaim.link": "Ein Problem mit diesem Artikel melden",
    "EInvoice.products.size": "Größe: {size}",
    "FaqCard.Headline": "Fragen?{br}Wir sind für dich da",
    "FlexikontoContainer.myFlexAccount": "Mein Flexikonto",
    "Gender.female": "Frau",
    "Gender.male": "Herr",
    "Joe.disconnect.label": "Kontoverknüpfung trennen",
    "Joe.disconnect.not-connected.text": "Ihr Konto ist nicht mit Ihrem jö Konto verknüpft.",
    "Joe.disconnect.success": "Ihr Konto wurde erfolgreich getrennt.",
    "Joe.disconnect.text": "Ihr Universal-Konto ist mit Ihrem jö Konto verknüpft. Sie sammeln bei jedem Einkauf Ös.",
    "JoeConnectAccountCard.headline": "jö Bonus Club",
    "LoginDecider.accessDenied.error": "Als EU Kunde kann dir leider kein Zugriff auf Mein Konto ermöglicht werden.",
    "LoungeContainer.advantage0": "<b>Gratis Speditionslieferung:</b>{break}Pro Bestellung 39,99€ sparen",
    "LoungeContainer.advantage1": "<b>Gratis Versand:</b>{break}Pro Bestellung 3,99€ sparen.",
    "LoungeContainer.advantage2": "<b>1 Jahr Zusatzgarantie:</b>{break}3 Jahre Garantie (gesetzl. Gewährleistung nicht eingeschränkt)",
    "LoungeContainer.advantage3": "-",
    "LoungeContainer.advantage4": "-",
    "LoungeContainer.advantage5": "-",
    "LoungeContainer.advantage6": "-",
    "LoungeContainer.advantage7": "-",
    "LoungeContainer.advantage8": "-",
    "LoungeContainer.advantage9": "-",
    "LoungeContainer.advantagesHeadline": "Mit OTTO UP profitierst du von diesen exklusiven Vorteilen:",
    "LoungeContainer.businessPartnerNo": "Meine Kundennummer",
    "LoungeContainer.cancel": "OTTO-UP Liefer-Flat kündigen",
    "LoungeContainer.cancel.info": "Deine OTTO UP Vorteile sind dir bis Ablauf eines Jahres nach Zeitpunkt der Anmeldung sicher. Mit exklusiven Aktionen und Vorteilen möchten wir treuen Mitgliedern wie dir DANKE sagen. Schön, dass du ein Teil davon bist.{break}Wenn du den OTTO UP Vorteilsclub jedoch verlassen möchtest, klicke bitte auf den Button “aus dem OTTO UP Club austreten”.",
    "LoungeContainer.cancel.modal.headline": "Schade, dass du den OTTO UP Vorteilsclub verlassen möchtest",
    "LoungeContainer.cancel.stay": "Im OTTO UP Vorteilsclub bleiben",
    "LoungeContainer.cancelPremium.error": "Das hat leider nicht geklappt. Bitte versuche es nochmal.",
    "LoungeContainer.cancelPremium.success": "Du hast deine OTTO UP Mitgliedschaft erfolgreich gekündigt. An welchem Tag deine Mitgliedschaft endet, siehst du auf der Übersicht unter \"Mein Status\".",
    "LoungeContainer.datastatus": "Datenstand",
    "LoungeContainer.extensionHint": "*Dein Kundenkonto wird automatisch mit dem neuen Jahresbeitrag belastet.",
    "LoungeContainer.freeMembershipHint": " ",
    "LoungeContainer.goal": "Mein Umsatzziel",
    "LoungeContainer.importantOverview": "Meine Daten",
    "LoungeContainer.introtext": "Deine Shopping-Vorteile zum UPheben",
    "LoungeContainer.mystatus": "Meine Liefer-Flat",
    "LoungeContainer.orderCount": "Versandkostenfreie Bestellungen",
    "LoungeContainer.orders": "Mein aktueller Jahresumsatz",
    "LoungeContainer.pageHeadline": "{firstName} Mein OTTO UP",
    "LoungeContainer.renewal": "Liefer-Flat-Verlängerung am*:",
    "LoungeContainer.retursHint": " ",
    "LoungeContainer.savings": "Meine Ersparnis",
    "LoungeContainer.since": "Liefer-Flat gültig seit:",
    "LoungeContainer.until": "Mitgliedschaft endet am",
    "Maturities.RVC.DE.minimalPayment": "Gesamt zu zahlen",
    "Maturities.RVC.DE.openPayments": "davon noch offen",
    "Maturities.RVC.DeductedPromotion": "davon Prämie Restschutz",
    "Maturities.RVC.minimalPayment": "aktuelle Mindestrate",
    "Maturities.RVC.openPayments": "Deine offenen Zahlungen",
    "Maturities.RVC.promotion": "aktuelle Prämie Zahlschutz",
    "Maturities.RVC.sum": "Dein fälliger Betrag",
    "Maturities.RVC.sum.Subheadline": "Ihr fälliger Betrag",
    "Maturities.accountBalanced": "Dein Konto ist ausgeglichen",
    "Maturities.bookingsLink": "Alle Buchungen",
    "Maturities.currentBalance": "Kontostand",
    "Maturities.currentMaturities": "Aktuell fällige Zahlungen",
    "Maturities.error": "Entschuldige bitte, wir können deinen Kontostand zur Zeit leider nicht anzeigen. <reload>Bitte lade die Seite neu!</reload> Falls der Fehler weiterhin besteht, haben wir intern ein technisches Problem und sind bereits dabei es zu beheben. Versuche es dann später noch einmal. Vielen Dank für dein Verständnis.",
    "Maturities.grandTotal": "Gesamt zu zahlen",
    "Maturities.headline.bookings": "Das Wichtigste in Kürze",
    "Maturities.headline.overview": "Rechnungen / Zahlungen",
    "Maturities.hideDetails": "Details verbergen",
    "Maturities.nextMaturities.headline": "Kommende fällige Zahlungen",
    "Maturities.nextMaturity": "fällig am ",
    "Maturities.openBalance": "OFFENER SALDO",
    "Maturities.overdueMaturities": "Überfällige Zahlungen",
    "Maturities.paymentPlanOverview": "Übersicht nächste Zahltermine",
    "Maturities.positiveBalance": "Guthaben",
    "Maturities.showDetails": "Details anzeigen",
    "MinimalMaturities.customAmount": "Auf Ihren Antrag hin wurde der Betrag angepasst",
    "MinimalMaturities.grandTotal": "Minimal zahlbar",
    "MinimalMaturities.maturityDate": "Am {date}{break}wird dieser Betrag{break}eingezogen",
    "MinimalMaturities.monthlyRate": "Monatlicher Betrag",
    "MinimalMaturities.overdue": "Rückstand",
    "MyAccount.Notification.DelayedDeliveryWarning": "Aufgrund der aktuellen Hochwasserlage können unsere Dienstleister nicht alle Gebiete hindernisfrei erreichen. Deshalb kann es in betroffenen Gebieten leider zu einer Verzögerung der Auslieferung kommen.{break}Vielen Dank für dein Verständnis.",
    "MyAccountContainer.accessDenied.error": "Als EU Kunde kann dir leider kein Zugriff auf Mein Konto ermöglicht werden.",
    "Navigation.MyAccount.Bypass.Headline": "Mein Konto Navigation überspringen",
    "Navigation.headline": "Mein Konto",
    "OverviewContainer.Kim.title": "Wie gefällt dir die Übersichtsseite?",
    "OverviewContainer.ReturnsInformation.card.headline": "Rücksendungen",
    "OverviewContainer.ReturnsInformation.date": "Gutschrift angewiesen am:",
    "OverviewContainer.ReturnsInformation.empty.message": "Bei uns ist keine Rücksendung eingegangen.",
    "OverviewContainer.ReturnsInformation.goToAllReturns.text": "Alle Rücksendungen",
    "OverviewContainer.ReturnsInformation.item.number.label": "Art.-Nr.:",
    "OverviewContainer.ReturnsInformation.item.quantity.label": "Menge:",
    "OverviewContainer.ReturnsInformation.item.size.label": "Größe:",
    "OverviewContainer.ShipmentInformation.card.headline": "Bestellungen auf dem Weg",
    "OverviewContainer.ShipmentInformation.fallback.text": "Leider ist keine Sendung zu dir unterwegs.",
    "OverviewContainer.ShipmentInformation.goToAll": "Alle Bestellungen & Lieferungen",
    "PartialPayment.AccountBalanceHeadline": "Kontostand",
    "PartialPayment.AccountInformationReceiver": "Empfänger",
    "PartialPayment.AllBookings": "Alle Buchungen",
    "PartialPayment.Birthdate": "Bitte überweise <strong>{currentAmountDue}</strong> bis zum <strong>{currentAmountDueDate}</strong> auf folgendes Konto:",
    "PartialPayment.CardHeadline": "Flexikonto Teilzahlung",
    "PartialPayment.CurrentAmountDue": "Dein fälliger Betrag",
    "PartialPayment.InlineNotification": "Unser Treuebonus für dich: {break}Du kannst noch für bis zu {amountUntilChange} bei Otto bestellen – Deine aktuelle monatliche Mindestrate bleibt gleich!",
    "PartialPayment.InstructionsQr": "Bezahl  bequem über den unten stehenden QR-Code in der App deiner Bank: ",
    "PartialPayment.MinimumRate": "Aktuelle Mindestrate",
    "PartialPayment.Or": "ODER",
    "PartialPayment.Purpose": "Zahlungsreferenz:",
    "PartialPayment.RsvFee": "Aktuelle Prämie Zahlschutz",
    "PartialPayment.RsvFeeIncluded": "inkl. Prämie Zahlschutz",
    "PartialPayment.ToMyAccountStatements": "Zu meinen Kontoauszügen",
    "PartialPayment.openBalance": "OFFENER SALDO",
    "PasswordForget.error.general": "Ihre Anfrage kann gerade nicht bearbeitet werden. Versuchen Sie es später erneut.",
    "PasswordForget.password.change.success": "Passwort erfolgreich geändert.",
    "PaymentPause.CardHeadline": "Flexikonto Zahlpause",
    "PaymentPause.FurtherInstructions": "Alle Informationen zur Flexikonto Zahlpause",
    "PaymentPause.InactivelInstructions": "Wähle die Flexikonto Zahlpause bei deinem nächsten Einkauf  im Bestellschritt Zahlung und bezahle erst nach 3 Monaten.*{break}* Aufpreis 4,99% vom Bestellwert. Bonität vorausgesetzt.",
    "PaymentPause.InactivelSubheadline": "Du hast aktuell keine aktive Zahlpause.",
    "PaymentPause.InitialPurchaseDate": "Einkaufsdatum",
    "PaymentPause.PurchaseAmount": "Betrag",
    "PaymentPause.PurchaseDueDate": "ist fällig am",
    "PaymentPause.PurchaseFrom": "Einkauf vom ",
    "PaymentPause.activeInstructions": "Hier findest du eine Übersicht über deine aktiven Zahlpausen und dem Fälligkeitsdatum. ",
    "PaymentPlan.due": "Fällig zum ",
    "PaymentPlan.empty": "Zum {date} sind keine Zahltermine vorhanden!",
    "PaymentPlan.error": "Deine Zahlungen können gerade nicht angezeigt werden. Bitte versuche es in Kürze erneut.",
    "PaymentPlan.headline": "Deine nächsten Zahlungen",
    "PaymentPlan.hintHeadline": "Bitte beachte:",
    "PaymentPlan.hintText": "Buchungen aus der Gesamtübersicht werden hier auf verschiedene Fälligkeitstermine aufgeteilt. Die einzelnen Rechnungen findest du eventuell unter derselben Belegnummer bei unterschiedlichen Fälligkeitsterminen wieder.",
    "PaymentPlan.showInvoice": "Rechnung Nr. {document} ansehen",
    "PaymentProtectionCard.BulletPointHead": "<b>In folgenden Lebenslagen bist du und dein/e im gemeinsamen Haushalt lebende/r Partner*In geschützt:</b>",
    "PaymentProtectionCard.CompletePaymentProtection": "Jetzt den Flexikonto Zahlschutz abschließen",
    "PaymentProtectionCard.Enabled": "Der Flexikonto Zahlschutz ist aktiv",
    "PaymentProtectionCard.GoodToKnow": "<b>Warum ist der Flexikonto Zahlschutz so beliebt?</b>",
    "PaymentProtectionCard.GoodToKnowText": "Begleichung des offenen Saldos deines Kundenkontos bis max. € 5.000.{br}{br}Der Versicherungsbeitrag beträgt 1,15% des offenen Saldos deines Kundenkontos. Besteht kein offener Saldo, ist auch kein Beitrag zu leisten.{br}{br}Eine Kündigung ist jederzeit möglich.",
    "PaymentProtectionCard.Headline": "Flexikonto Zahlschutz",
    "PaymentProtectionCard.OldInsurance0": "unverschuldete Arbeitslosigkeit",
    "PaymentProtectionCard.OldInsurance1": "Arbeitsunfähigkeit",
    "PaymentProtectionCard.OldInsurance2": "Ableben",
    "PaymentProtectionCard.Service": "Den genauen Umfang und Informationen zu deinem Versicherungsprodukt entnehme bitte den Allgemeinen Versicherungsbedingungen.{br}{hr}{br}<b>Im Versicherungsfall helfen wir dir gerne weiter:</b>{br}{br}Kundenservice Cardif Versicherung:{br}01/533 98 78 83{br}leistung@cardif.com",
    "PaymentProtectionCard.YoungInsurance0": "unverschuldete Arbeitslosigkeit",
    "PaymentProtectionCard.YoungInsurance1": "Arbeitsunfähigkeit",
    "PaymentProtectionCard.YoungInsurance2": "Ableben",
    "PaymentProtectionCard.YoungInsurance3": "-",
    "PaymentProtectionCard.YoungInsurance4": "-",
    "PaymentSettings.Headline": "Gespeicherte Zahlungsdaten",
    "PaymentSettings.confirmDelete": "Löschen kann nicht rückgängig gemacht werden",
    "PaymentSettings.confirmDeleteButton": "löschen bestätigen",
    "PaymentSettings.creditCard": "Kreditkarte",
    "PaymentSettings.creditCard.number": "Kartennummer",
    "PaymentSettings.creditCard.validThru": "Gültig bis",
    "PaymentSettings.deleteButton": "löschen",
    "PaymentSettings.directDebit": "Bankeinzug",
    "PaymentSettings.directDebit.download": "SEPA Mandat herunterladen",
    "PaymentSettings.directDebit.new": "SEPA-Lastschriftmandat",
    "PaymentSettings.empty.addDirectDebit": "Bankeinzug anlegen",
    "PaymentSettings.empty.hint.headline": "Schneller & sicher einkaufen",
    "PaymentSettings.empty.hint.text": "Du kannst im Zuge einer Bestellung deine Kreditkartendaten hinterlegen, sodass sie hier gespeichert werden. So ist es beim nächsten Einkauf nicht mehr notwendig, alle Daten erneut einzugeben und du sparst Zeit.",
    "PaymentSettings.hint.headline": "Woher stammen die Daten?",
    "PaymentSettings.hint.text": "Hier findest du die Daten, die du für weitere Einkäufe hinterlegt hast. Per Klick auf „löschen“ kannst du die Daten natürlich endgültig entfernen.",
    "PaymentSettings.successDeleteCreditCard": "Kreditkartendaten wurden erfolgreich gelöscht",
    "PaymentSettings.successDeleteDirectDebit": "Bankdaten wurden erfolgreich gelöscht",
    "PersonalCoupon.ActiveChip": "Gutschein angewendet",
    "PersonalCoupon.ActiveHint": "Der Gutschein wird in Schritt 5 „Prüfen und Bestellen“ angewendet.",
    "PersonalCoupon.Button": "Gutschein anwenden",
    "PersonalCoupon.ConditionsHeadline": "*Gutscheinbedingungen",
    "PersonalCoupon.Header": "Dein Dankeschön:",
    "PersonalData.Birthdate": "Geburtsdatum",
    "PersonalData.BusinessPartnerNo": "Kundennummer",
    "PersonalData.EmailAddress": "E-Mail-Adresse",
    "PersonalData.Headline": "Persönliche Daten",
    "PersonalData.Iban": "IBAN",
    "PersonalData.InvoiceAddress": "Rechnungsanschrift",
    "PersonalData.Password": "Passwort",
    "PersonalData.PasswordHint": "Das Passwort wird aus Sicherheitsgründen nicht angezeigt.",
    "PersonalData.Phone": "Telefon",
    "PersonalData.Verified.Text": "Bestätigt",
    "PersonalData.addressmodal.headline": "Neue Adresse?",
    "PersonalData.change": "ändern",
    "PersonalData.changepassword.success": "Passwort erfolgreich geändert.",
    "PersonalData.emailmodal.headline": "Neue E-Mail-Adresse?",
    "PersonalData.ibanmodal.headline": "IBAN ändern",
    "PersonalData.passwordmodal.headline": "Neues Passwort?",
    "PersonalData.phone.fixed": "Festnetz",
    "PersonalData.phone.mobile": "Handy",
    "PersonalData.phonemodal.headline": "Neue Telefonnummer?",
    "PersonalDataContainer.Kim.title": "Wie gefällt dir die Persönliche Daten Seite?",
    "PrepaymentHint.text": "Die Bestellung wird erst bearbeitet, wenn eine Anzahlung in Höhe von {amount} bei uns eingegangen ist. Hinweis: Bestellte Artikel sind solange nicht reserviert.{space} Schnell, sicher, einfach – Jetzt zahlen per <link>EPS Online-Überweisung</link>",
    "ProductNotFoundHint.copy": "Ältere Bestelldaten können aus technischen Gründen leider nicht angezeigt werden. Versendete Artikel findest du aber unter <a>deinen Buchungen</a>.",
    "ProductNotFoundHint.title": "Artikel nicht gefunden?",
    "ReclamationForm.Article.Submit": "Übernehmen",
    "ReclamationForm.FormCancel": "Abbrechen",
    "ReclamationForm.FormSubmit": "Abschicken",
    "ReclamationForm.Information": "Bitte nutze dieses Formular nur für die Meldung von defekten oder beschädigten Artikeln, die repariert oder begutachtet werden sollen. Für alles andere nutze bitte unser Kontaktformular unter ",
    "ReclamationForm.InvoiceInfo": "Ihre Reklamation zur Rechnung {invoiceNumber} vom {invoiceDate}",
    "ReclamationForm.Replacement.Headline": "Benötigen Sie ein Ersatzteil? Ergänzen Sie bitte folgende Angaben",
    "ReclamationForm.Shipping.Headline": "Ist deine Lieferadresse abweichend von deiner Kontoanschrift?",
    "ReclamationForm.Shipping.Submit": "Übernehmen",
    "ReclamationForm.Submit.Error": "Ihre Reklamation konnte leider gerade nicht durchgeführt werden. Versuchen Sie es doch später erneut!",
    "ReclamationForm.articleDescription.required": "Die Artikelbeschreibung wird benötigt!",
    "ReclamationForm.articleNumber.required": "Die Artikelnummer wird benötigt!",
    "ReclamationForm.city.required": "Der Ort wird benötigt!",
    "ReclamationForm.email.required": "Die E-Mail-Adresse wird benötigt!",
    "ReclamationForm.emailAddress": "E-Mail:",
    "ReclamationForm.error": "Du bist aktuell nicht angemeldet! Melde dich doch bitte an!",
    "ReclamationForm.error.invalidData": "Wir konnten deine Daten aktuell nicht finden. Versuche es doch später erneut!",
    "ReclamationForm.firstName.required": "Der Vorname wird benötigt!",
    "ReclamationForm.headlineArticle": "Artikel und Bestellinformationen",
    "ReclamationForm.headlineCustomer": "Kundeninformation",
    "ReclamationForm.headlineShipping": "Lieferadresse (falls abweichend von Kontoanschrift)",
    "ReclamationForm.houseNumber.required": "Die Hausnummer wird benötigt!",
    "ReclamationForm.labelArticleDescription": "Artikelbeschreibung:",
    "ReclamationForm.labelArticleNumber": "Artikelnummer:",
    "ReclamationForm.labelBestReachedPhoneNumber": "am besten erreichbar:",
    "ReclamationForm.labelCity": "Ort:",
    "ReclamationForm.labelCustomerNo": "Kundennummer:",
    "ReclamationForm.labelDataTypeShield": "Daten-Typenschild:",
    "ReclamationForm.labelFirstName": "Vorname:",
    "ReclamationForm.labelHouseNumber": "Hausnummer:",
    "ReclamationForm.labelLastName": "Nachname:",
    "ReclamationForm.labelReclamationDescription": "Mängelbeschreibung:*",
    "ReclamationForm.labelReclamationReason": "Reklamationsgrund:",
    "ReclamationForm.labelReplacementNumber": "Ersatzteilnummer:",
    "ReclamationForm.labelStreet": "Straße:",
    "ReclamationForm.labelZipCode": "PLZ:",
    "ReclamationForm.lastName.required": "Der Nachname wird benötigt!",
    "ReclamationForm.primaryPhoneNumber": "Telefon:",
    "ReclamationForm.reclamationDescription.required": "Die Mängelbeschreibung wird benötigt!",
    "ReclamationForm.reclamationReasonDamaged": "Artikel defekt",
    "ReclamationForm.reclamationReasonIncomplete": "Artikel unvollständig",
    "ReclamationForm.reclamationReasonOther": "Sonstiges",
    "ReclamationForm.reclamationReasonPlaceholder": "Bitte auswählen",
    "ReclamationForm.reclamationReasonWrongPrice": "Falscher Preis",
    "ReclamationForm.reclamationReasonWrongProduct": "Falscher Artikel geliefert",
    "ReclamationForm.street.required": "Die Straße wird benötigt!",
    "ReclamationForm.zipCode.required": "Die PLZ wird benötigt!",
    "ReturnDeclaration.CTA.Button.Label": "Weitere Sendungsnummer hinzufügen",
    "ReturnDeclaration.Description": "Dein Paket ist bereits auf dem Weg zu uns?{br}Dann teile uns einfach deine Tracking- bzw. Sendungsnummer mit.",
    "ReturnDeclaration.Disclaimer": "Speditionsartikel sind leider ausgeschlossen.",
    "ReturnDeclaration.Headline": "Rücksendung aufgegeben?",
    "ReturnDeclaration.InfoBox.Headline": "Wo finde ich meine Sendungsnummer?",
    "ReturnDeclaration.InfoBox.Text": "Du findest deine Tracking- bzw. Sendungsnummer auf dem Beleg, den du bei der Abgabe deiner Rücksendung bekommen hast.",
    "ReturnDeclaration.Submit.Button.Label": "Sendungsnummer abschicken",
    "ReturnDeclarationCard.DateInput.Error": "Das Abgabedatum deiner Rücksendung liegt zu weit zurück",
    "ReturnDeclarationCard.DateInput.Format.Error": "Bitte korrigiere das Datum (Format: TT.MM.JJJJ)",
    "ReturnDeclarationCard.DateInput.Future.Error": "Verwende {date} oder einen früheren Wert",
    "ReturnDeclarationCard.DateInput.Label": "Abgabedatum der Rücksendung",
    "ReturnDeclarationCard.InfoModal.Content": "Mit dem Datum können wir deine Sendungsdaten eindeutig in unserer Datenbank zuordnen.",
    "ReturnDeclarationCard.InfoModal.Headline": "Abgabedatum der Rücksendung",
    "ReturnDeclarationCard.InfoModal.Subheadline": "Warum benötigen wir das Abgabedatum?",
    "ReturnDeclarationCard.Input.Label": "Sendungsnummer",
    "ReturnDeclarationCard.Input.Placeholder": "z.B. 123456789",
    "ReturnDeclarationCard.MissingInput.InlineError": "Bitte vervollständige deine Eingabe oder lösche diese",
    "ReturnDeclarationCard.Return.Eighth": "Achte Rücksendung",
    "ReturnDeclarationCard.Return.Fifth": "Fünfte Rücksendung",
    "ReturnDeclarationCard.Return.First": "Rücksendung",
    "ReturnDeclarationCard.Return.Fourth": "Vierte Rücksendung",
    "ReturnDeclarationCard.Return.Ninth": "Neunte Rücksendung",
    "ReturnDeclarationCard.Return.Second": "Zweite Rücksendung",
    "ReturnDeclarationCard.Return.Seventh": "Siebte Rücksendung",
    "ReturnDeclarationCard.Return.Sixth": "Sechste Rücksendung",
    "ReturnDeclarationCard.Return.Tenth": "Zehnte Rücksendung",
    "ReturnDeclarationCard.Return.Third": "Dritte Rücksendung",
    "ReturnDeclarationContainer.Success.Description": "Da wir nun wissen, dass dein Paket auf dem Weg zu uns ist, können wir dies direkt in unseren Systemen vormerken.{br}{br}Wir melden uns bei dir, wenn dein Paket bei uns eingegangen ist!",
    "ReturnDeclarationContainer.Success.GoToShop": "Zurück zum Shop",
    "ReturnDeclarationContainer.Success.Headline": "Vielen Dank!",
    "ReturnDeclarationContainer.accessDenied.error": "Dieser Link ist leider nicht gültig.",
    "ReturnShipmentInfo.InstructionsForReturn.contactHeading.Bring": "So kannst du deinen Artikel an uns zurückschicken",
    "ReturnShipmentInfo.InstructionsForReturn.contactHeading.Pickup": "So kannst du deine Speditionsartikel zurückschicken",
    "ReturnShipmentInfo.InstructionsForReturn.contactMessage.Bring": "Benutze ganz einfach das Rücksende-Etikett, welches deiner Sendung beiliegt. Ansonsten kontaktiere bitte unsere Kundenbetreuung unter <link>{emailAddress}</link> oder {phoneNumber}",
    "ReturnShipmentInfo.InstructionsForReturn.contactMessage.Pickup": "Bitte kontaktiere unsere Kundenbetreuung unter {phoneNumber} zur Abholung deiner Rücksendung.",
    "ReturnShipmentInfo.InstructionsForReturn.emailAddress": "kundenservice@ottoversand.at",
    "ReturnShipmentInfo.InstructionsForReturn.errorToast": "Ups. Etwas ist schief gelaufen. Probiere es später noch einmal oder wende dich unter {phoneNumber} an unseren Kundenservice",
    "ReturnShipmentInfo.InstructionsForReturn.href": "/service-hilfe/kontakt",
    "ReturnShipmentInfo.InstructionsForReturn.noteHeading": "Bitte beachte:",
    "ReturnShipmentInfo.InstructionsForReturn.noteMessage": "Sende nur Artikel aus derselben Lieferung in einem Paket an uns zurück, damit wir deine Rücksendung bearbeiten und eindeutig zuordnen können. Falls du weitere Artikel aus anderen Lieferungen zurückgeben möchtest, müssen diese pro Lieferung verpackt und zurückgeschickt werden.",
    "ReturnShipmentInfo.InstructionsForReturn.packingHeading": "1. Verpacken",
    "ReturnShipmentInfo.InstructionsForReturn.packingMessage": "Verpacke bitte deinen Artikel in das Paket - Idealerweise in das bereits von uns verwendete. Vergesse auch nicht, deinen Lieferschein mit den Retourengründen beizulegen.",
    "ReturnShipmentInfo.InstructionsForReturn.parcelHeading": "3. PaketShop",
    "ReturnShipmentInfo.InstructionsForReturn.parcelMessage": "Gib deine Rücksendung in einer Filiale der Österreichischen Post oder in einem Hermes PaketShop ab.",
    "ReturnShipmentInfo.InstructionsForReturn.phoneNumber": "0316 - 606 888",
    "ReturnShipmentInfo.InstructionsForReturn.printLabelHeading": "2. Rücksende-Etikett",
    "ReturnShipmentInfo.InstructionsForReturn.printLabelMessage": "Du kannst dir hier ein neues Rücksende-Etikett erstellen lassen, das du ausdruckst und auf das Paket klebst. Den Rest erledigt eine Filiale der Österreichischen Post oder der Hermes PaketShop für dich.",
    "ReturnShipmentInfo.InstructionsForReturn.printLabelMessage2": "<semiBold>Bitte beachte: </semiBold> Wenn du das beigelegte Rücksende-Etikett schon genutzt hast, wende dich bitte an unseren Kundenservice unter {phoneNumber} bzw. {emailAddress} und fordere ein neues Etikett an. Nur so kommt deine Rücksendung auch wirklich bei uns an.",
    "ReturnShipmentInfo.InstructionsForReturn.relaxHeading": "4. Entspannen",
    "ReturnShipmentInfo.InstructionsForReturn.relaxMessage": "Danach kannst du dich entspannt zurücklehnen. Sobald deine Rücksendung von uns bearbeitet wurde, informieren wir dich per E-Mail und erstatten dir bereits gezahlte Beträge.",
    "ReturnShipmentInfo.modalMainHeader.Before": "Rücksende-Etikett erstellen",
    "ReturnShipmentInfo.modalMainHeader.Bring": "Artikel zurückschicken",
    "ReturnShipmentInfo.modalMainHeader.Online": "Rücksende-Etikett erstellen",
    "ReturnShipmentInfo.modalMainHeader.Pickup": "Speditionsartikel zurückschicken",
    "ReturnShipmentInfo.modalSubHeader.Before": "So kannst du deinen Artikel an uns zurückschicken",
    "ReturnShipmentInfo.modalSubHeader.Online": "So kannst du deinen Artikel an uns zurückschicken",
    "ReturnShipmentInfo.phoneNumber": "0316 - 606 888",
    "ReturnShipmentInfo.returnType.Before": "Rücksende-Etikett anzeigen",
    "ReturnShipmentInfo.returnType.Bring": "Zurück zu meinen Bestellungen",
    "ReturnShipmentInfo.returnType.Online": "Rücksende-Etikett anzeigen",
    "ReturnShipmentInfo.returnType.Pickup": "Zurück zu meinen Bestellungen",
    "ServiceContactTile.callUs": "Ruf uns an",
    "ServiceContactTile.questions": "Hast du Fragen?",
    "ServiceContactTile.write": "Schreib uns",
    "ServiceContactTile.yourCustomerNumber": "Deine Kundennummer",
    "ShipmentCardAction.RecoSlider.headline": "Empfehlungen für dich",
    "ShipmentCardAction.similarproducts.link": "Ähnliche Artikel",
    "ShipmentCardDetails.hint.delivered": "Details zum Standort deines Pakets findest du über die Sendungsverfolgung.",
    "ShipmentCardDetails.hint.downpayment": "Bitte beachte unsere Anzahlungshinweise an den einzelnen Artikeln",
    "ShipmentCardDetails.label.carrier": "Versandpartner",
    "ShipmentCardDetails.label.deliveryAddress": "Lieferanschrift",
    "ShipmentCardDetails.label.deliveryService": "Lieferservice",
    "ShipmentCardDetails.label.hint": "Hinweis",
    "ShipmentCardDetails.label.invoiceLink": "Rechnung ansehen",
    "ShipmentCardDetails.label.noTrackingAvailable": "Es sind keine Track & Trace-Informationen verfügbar",
    "ShipmentCardDetails.label.numberOfPackages": "Paketanzahl",
    "ShipmentCardDetails.label.orderDate": "Bestelldatum",
    "ShipmentCardDetails.label.packageType": "Verpackungsart",
    "ShipmentCardDetails.label.paymentType": "Zahlungsart",
    "ShipmentCardDetails.label.trackingId": "Sendungs ID",
    "ShipmentCardDetails.link.desiredDelivery": "Wunschzustellung {optPackageNo}",
    "ShipmentCardDetails.link.returnLabelShow": "Rücksende-Etikett anzeigen",
    "ShipmentCardDetails.link.tracking": "Sendungsverfolgung {optPackageNo}",
    "ShipmentCardDetails.package.indicator": "(Paket {current} von {total})",
    "ShipmentCardDetails.returnLabelCreated": "Rücksende-Etikett erstellt",
    "ShipmentCardDetails.trackingUrlError": "Etwas ist schief gelaufen. Bitte versuche es später erneut.",
    "ShipmentInfoCard.details.headline": "Bestelldetails & Sendungsverfolgung",
    "ShipmentInfoHeader.headline": "Meine Bestellungen und Lieferungen",
    "ShipmentInfoHeader.text": "Verschaffe dir einen Überblick, welchen Status deine Bestellungen und Lieferungen haben.",
    "ShipmentInfoTypeSelect.option.all": "Alle",
    "ShipmentInfoTypeSelect.option.canceled": "Storniert",
    "ShipmentInfoTypeSelect.option.delivered": "Zugestellt",
    "ShipmentInfoTypeSelect.option.label": "Sendungsstatus:",
    "ShipmentInfoTypeSelect.option.ordered": "Bestellt",
    "ShipmentInfoTypeSelect.option.returned": "Zurückgeschickt",
    "ShipmentInfoTypeSelect.option.sent": "Unterwegs",
    "ShipmentInfos.cancel.error.message": "Beim Stornieren der Bestellung ist ein Fehler aufgetreten.",
    "ShipmentInfos.headline": "Bestellungen schneller finden:",
    "ShipmentInfos.loadMore": "weitere laden",
    "ShipmentInfos.nodata-hint": "Bitte wähle den gewünschten Status oder Zeitraum.",
    "ShipmentInfos.request.error.message": "Beim Laden der Bestellungen ist ein Fehler aufgetreten.",
    "ShipmentInfosContainer.Kim.title": "Wie gefällt dir die Bestellungen und Lieferungen Seite?",
    "ShipmentInfosProductView.label.amount": "Anzahl",
    "ShipmentInfosProductView.modal.message.canceled.main": "Ihr Artikel wurde storniert.",
    "ShipmentInfosProductView.modal.message.canceled.sub": "Beim nächsten Mal findest du bestimmt etwas Passendes für dich.",
    "ShipmentInfosProductView.modal.title": "Möchtest Du wirklich stornieren?",
    "ShipmentInfosProductView.modal.title.success": "Stornierung erfolgreich",
    "ShipmentInfosProductView.productLink": "zum Artikel",
    "ShipmentInfosShipmentListType.all": "Alle",
    "ShipmentInfosShipmentListType.awaiting_downpayment": "Warten auf Anzahlung",
    "ShipmentInfosShipmentListType.canceled": "Storniert",
    "ShipmentInfosShipmentListType.delivered": "Zugestellt",
    "ShipmentInfosShipmentListType.in_delivery": "In Lieferung",
    "ShipmentInfosShipmentListType.ordered": "Bestellt",
    "ShipmentInfosShipmentListType.returnLabel": "Rücksende-Etikett",
    "ShipmentInfosShipmentListType.return_shipment": "Rücksende-Etikett",
    "ShipmentInfosShipmentListType.returned": "Zurückgeschickt",
    "ShipmentInfosShipmentListType.returnlabel_created": "Rücksende-Etikett erstellt",
    "ShipmentInfosShipmentListType.sent": "Unterwegs",
    "ShipmentInfosShipmentListType.shipment_prepared": "Versand vorbereitet",
    "ShipmentTimeRangeSelect.option.all": "Alle",
    "ShipmentTimeRangeSelect.option.days": "{amount} Tage",
    "ShipmentTimeRangeSelect.option.label": "Zeitraum:",
    "ShipmentTimeRangeSelect.option.weeks": "{amount} Wochen",
    "VerificationNotification.Text": "Bestätige deine E-Mail-Adresse, um die <strong>Sicherheit deines Kontos zu erhöhen</strong>.",
    "VerificationNotification.VerifyAgainCTA": "Mail erneut anfordern",
    "VerificationNotification.VerifyAgainText": "Wir haben dir einen <strong>Bestätigungslink</strong> an <strong>{email}</strong> geschickt. Du hast keine Mail bekommen? Sieh ggf. noch mal im Spamordner nach. Oder du kannst die ",
    "VerificationNotification.VerifyCTA": "E-Mail Adresse bestätigen",
    "VerificationNotification.error": "Das hat leider nicht funktioniert. Versuch es bitte nochmal.",
    "blackFridayNotificationText": "Aufgrund des sehr hohen Bestelleingangs bitten wir um Verständnis, dass die Zustellung momentan 2-3 Tage länger dauern kann!",
    "meta.title.addressbook": "Mein Adressbuch | Mein Konto",
    "meta.title.bankstatements": "Meine Kontoauszüge | Mein Konto",
    "meta.title.bookings": "Meine Buchungen | Mein Konto",
    "meta.title.callcenterLogin": "Callcenter Login | Mein Konto",
    "meta.title.default": "Mein Konto",
    "meta.title.flexikonto": "Mein Flexikonto | Mein Konto",
    "meta.title.overview": "Übersicht | Mein Konto",
    "meta.title.passwordForget": "Passwort vergessen",
    "meta.title.personaldata": "Meine persönlichen Daten | Mein Konto",
    "meta.title.premiumlounge": "Meine Lounge | Mein Konto",
    "meta.title.shipmentinfos": "Meine Bestellungen & Lieferungen | Mein Konto",
    "AccountInformation.bic": "BIC: <split>RZBAATWW</split>",
    "AccountInformation.iban": "IBAN: <split>AT05 3100 0001 0063 7934</split>",
    "AccountInformation.receiverAdditionals": "Raiffeisen Bank International AG",
    "AccountInformation.receiverName": "Otto GmbH",
    "ServiceContactTile.emailAddress": "kundenservice@<wbr />ottoversand.at",
    "ServiceContactTile.phoneNumber": "0316 - 606 888",
    "ServiceContactTile.phoneTimes": "täglich von 7 bis 22 Uhr",
    "PaymentProtectionCard.OldInsurance3": "-",
    "PaymentProtectionCard.OldInsurance4": "-",
    "ServiceContactTile.emailAddressEmployee": "kundenservice@<wbr />ottoversand.at",
    "ServiceContactTile.phoneNumberEmployee": "0316 - 606 888",
    "ServiceContactTile.phoneTimesEmployee": "täglich von 7 bis 22 Uhr"
  }
};
  
  export default deepmerge(ioLocale, messages);
  